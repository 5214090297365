<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";

import ECharts from "vue-echarts";

import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";

import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/grid";
import "echarts/lib/component/axis";

import {
  mixedBarChart,routesalesChart
} from "./dataechart";
import {
  linewithDataChart,
  dashedLineChart,
  splineAreaChart,
  columnChart,
  columnDatalabelChart,
  barChart,
  barChartAirline,
  mixedChart,
  radialChart,
  pieChart,
  donutChart
} from "./data-apex";

/**
 * Apex-chart component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
    DatePicker, Layout, PageHeader, "v-chart": ECharts },
  data() {
    return {
      title: "Dashboard",
      items: [],
      mixedBarChart: mixedBarChart,
      routesalesChart:routesalesChart,
      linewithDataChart: linewithDataChart,
      dashedLineChart: dashedLineChart,
      splineAreaChart: splineAreaChart,
      columnChart: columnChart,
      columnDatalabelChart: columnDatalabelChart,
      barChart: barChart,
      barChartAirline: barChartAirline,
      mixedChart: mixedChart,
      radialChart: radialChart,
      pieChart: pieChart,
      donutChart: donutChart,
      demoMonth: ""
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Month"
                    label-for="vendflight_vendorid"
                  >
                    
                    <date-picker  class="col-lg-4"
                      v-model="demoMonth"
                      type="month"
                      lang="en"
                      confirm
                      placeholder="Select Month"
                    ></date-picker>
                    to
                    <date-picker  class="col-lg-4"
                      v-model="demoMonth"
                      type="month"
                      lang="en"
                      confirm
                      placeholder="Select Month"
                    ></date-picker>
                  </b-form-group>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Top 5 Sales By Customer</h4>
            <!-- Bar Chart -->
            <apexchart
              class="apex-charts"
              height="350"
              type="bar"
              dir="ltr"
              :series="barChart.series"
              :options="barChart.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Top 5 Sales By Airline</h4>
            <!-- Bar Chart -->
            <apexchart
              class="apex-charts"
              height="350"
              type="bar"
              dir="ltr"
              :series="barChartAirline.series"
              :options="barChartAirline.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Sales Per Month</h4>
            <v-chart :options="mixedBarChart" autoresize />
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Top 5 Route Sales</h4>
            <v-chart :options="routesalesChart" autoresize />
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>